import React from 'react';
import SearchWidgetV2, { DefaultSearchWidget } from '@kyruus/search-widget-v2';
import {
  configIsInvalid,
  convertFromCustomerServiceConfig
} from 'Common/search-widget';

const SearchWidget = ({
  config,
  searchWidgetConfig,
  customerCode,
  options,
  typeaheadTerms,
  location,
  log,
  onSelect,
  onSubmit,
  onInputFocus
}) => {
  // whether or not we should use the old search_widget config object in CS
  const useLegacyConfig = config.darkship_KENG_31725 ? false : true;

  // the starting point for the config we pass to the widget; additional PMC-only overrides are passed below
  const widgetConfig = useLegacyConfig
    ? convertFromCustomerServiceConfig(config, customerCode)
    : searchWidgetConfig;

  // TODO see if we can remove this default widget at some point (the widget will accept a partial config
  // or NO config and still function with the defaults it maintains internally, though the theme would still
  // need to be passed here)
  if (!useLegacyConfig && configIsInvalid(searchWidgetConfig)) {
    // still use the theme object from CS in this case
    return (
      <DefaultSearchWidget
        id={customerCode}
        config={{
          customer_code: customerCode,
          index: config.index,
          params: {
            focus_on_widget: true
          },
          theme: config.theme
        }}
        options={options}
        log={log}
        useV8={false}
        useLegacyConfig={false}
        onSelect={onSelect}
        onSubmit={onSubmit}
        onInputFocus={onInputFocus}
        typeaheadTerms={typeaheadTerms}
        location={location}
      />
    );
  }

  // NOTE: index and focus_on_widget are always going to be passed as PMC-only overrides here
  return (
    <SearchWidgetV2
      id={customerCode}
      config={{
        ...widgetConfig,
        index: config.index,
        params: {
          ...widgetConfig.params,
          focus_on_widget: true
        }
      }}
      options={options}
      log={log}
      useV8={config.darkship_use_list_page_searchv9 ? false : true}
      useLegacyConfig={useLegacyConfig}
      onSelect={onSelect}
      onSubmit={onSubmit}
      onInputFocus={onInputFocus}
      typeaheadTerms={typeaheadTerms}
      location={location}
    />
  );
};

export default SearchWidget;
